import './components/component.cap-search-locality.js';
import './components/component.cap-search-city.js';
import './components/component.cap-search-street.js';

import './directives/cap-form.js';
import './directives/cap-country.js';
import './directives/cap-trigger.js';
import './directives/cap-place.js';
import './directives/cap-building.js';

import './services/service.capadresse.js';

/**
 * @class angular.capadresse
 * @memberOf angular
 * @description
 * Ce module met à disposition éléments nécessaires à l'utilisation du service Capaddress pour l'aide à la completion d'adresses.
 */
angular.module('capadresse', [
  'component.capSearchLocality',
  'component.capSearchCity',
  'component.capSearchStreet',
  'directive.capForm',
  'directive.capCountry',
  'directive.capTrigger',
  'directive.capPlace',
  'directive.capBuilding',
  'service.capadresse'
]);
