export default angular
  .module('directive.photoslurp', [])

  .directive('photoslurp', function () {
    return {
      restrict: 'E',
      scope: true,
      controllerAs: 'ctrl',
      /* @ngInject */
      controller: function ($element) {
        const ctrl = this;
        ctrl.isActive = function () {
          if ($('photoslurp .ps-image')?.length > 0) {
            return true;
          }
          return false;
        };
      }
    };
  });
