export default angular
  .module('component.input.quantity', [])

  .component('inputQuantity', {
    require: {
      productCtrl: '?^^productDetail'
    },
    bindings: {
      id: '<?idProduct',
      min: '<?',
      step: '<?',
      quantity: '<?',
      width: '<?',
      size: '@?',
      btnClass: '@?',
      watchDisabled: '@?',
      keyEnter: '&?',
      action: '&?'
    },
    template: '',
    /* @ngInject */
    controller: function ($scope, $element, $injector, $compile) {
      const ctrl = this;
      ctrl.device = $scope.device;

      ctrl.$onDestroy = () => {};

      ctrl.$onInit = () => {
        loadTemplate();
      };

      async function loadTemplate() {
        const response = await import(/* webpackChunkName: "input.quantity" */ 'import/js/components/input.quantity/component.input.quantity.html');
        $element.html(response.default);
        $compile($element.contents())($scope);
        init();
      }

      async function init() {
        const response = await import(/* webpackChunkName: "input.quantity.init" */ 'import/js/components/input.quantity/component.input.quantity.init.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('InitInputQuantity');
        service.init(ctrl, $scope);
      }
    }
  });
