import { pullAllBy, inRange } from 'lodash-es';
import cartPostProcess from 'import/js/services/cart.postprocess.js';

export default angular
  .module('service.app', [])

  /* @ngInject */
  .factory('AppService', function ($http, $rootScope, $q, $timeout, $templateCache, HttpService, ModalService, toastr, NetwaveService) {
    let appPromise;
    const cartRequests = [];
    let translate;
    let MobilityVisitorContext;

    const service = {
      getParams,
      resetParams,
      updateParams,
      getTranslate,
      addToCart,
      deleteLine,
      itemQuantityInCart,
      checkAvailability,
      getProductPrice,
      cartRequests,
      updateStore,
      updateKey,
      emailExists
    };
    return service;

    ////////////
    function getParams(refresh) {
      if (refresh || angular.isUndefined(appPromise)) {
        appPromise = $http({
          method: 'GET',
          url: `/App${refresh ? `?${new Date().getTime()}` : ''}`, // Fix IE11 cache
          headers: {
            'Content-type': 'application/json',
            'X-Requested-With': 'jsonHttpRequest'
          }
        })
          .then(response => {
            $rootScope.$broadcast('updateVisitorContext', response.data.VisitorContext);
            $rootScope.$broadcast('getParams', response.data.VisitorContext.IsLogged);
            let data = angular.copy(response.data.VisitorContext);
            MobilityVisitorContext = response.data.MTYVisitorContext;
            data = { ...data, Mobility: MobilityVisitorContext };
            data.Visitor.isPro = false;
            if (response.data.VisitorContext && response.data.VisitorContext.Visitor.IsCardPro) {
              data.Visitor.isPro = response.data.VisitorContext.Visitor.IsCardPro;
            }
            data.Cart = cartPostProcess(data.Cart);
            clearTemplateCache(refresh);
            return data;
          })
          .catch(error => {
            throw error;
          });
      }
      return appPromise;
    }

    function resetParams() {
      appPromise = undefined;
    }

    function updateParams(data, noevent) {
      data.Cart = cartPostProcess(data.Cart);
      if (!data.Mobility && MobilityVisitorContext) data.Mobility = MobilityVisitorContext;
      appPromise = $q.resolve(data);
      if (noevent) return;
      $rootScope.$broadcast('cartUpdate');
      if (data.Store) $rootScope.$broadcast('storeUpdate', null, { VisitorContext: data });
    }

    async function getTranslate() {
      if (angular.isUndefined(translate)) {
        try {
          const { data } = await $http({
            method: 'GET',
            url: '/Template/Translate/Translate',
            headers: {
              'Content-type': 'application/json',
              'X-Requested-With': 'jsonHttpRequest'
            }
          });
          translate = data;
        } catch (error) {
          throw error;
        }
      }
      return translate;
    }

    function addToCart(products, checkCumulativeProduct = false) {
      const productsToCart = {
        Products: [],
        CheckCumulativeProduct: checkCumulativeProduct
      };

      products.forEach(product => {
        productsToCart.Products.push({
          IDLine: product.idLine || 0,
          IDProduct: product.idProduct || 0,
          Reference: product.Reference || '',
          Quantity: product.quantity,
          Comment: product.Comment || '',
          Elements: product.Elements || null,
          Customization: product.Customization || null,
          CustomizablePrice: product.CustomizablePrice
            ? {
                Price: product.CustomizablePrice.current
              }
            : null
        });
      });
      const tmpId = new Date().getTime();
      cartRequests.push({ id: tmpId });
      return $http({
        method: 'POST',
        url: '/Product/AddToCart',
        headers: {
          'Content-type': 'application/json',
          'X-Requested-With': 'jsonHttpRequest'
        },
        data: productsToCart
      })
        .then(response => {
          pullAllBy(cartRequests, [{ id: tmpId }], 'id');
          // Netwave
          if (response.data.status === 'OK' && (!products || products !== null) && products.length > 0) {
            const product = response.data.results.Cart.Products.find(p => p.IDProduct === products[0].idProduct);
            if (product) {
              products[0].KitUnitTTCPrice = product.Product.IsKit ? Math.round(product.TTCAmount / product.Quantity * 100) / 100 : 0;
              products[0].IsKit = product.Product.IsKit;
            }
            if (products[0].quantity > 0) {
              NetwaveService.addArticleToCart(products[0], products[0].quantity);
            } else {
              NetwaveService.removeArticleFromCart(products[0], -(products[0].quantity));
            }
          }
          return response.data;
        })
        .catch(error => {
          throw error;
        });
    }

    function deleteLine(idLine, product, designation, img, imgSrc, message, fromCart) {
      $rootScope.$broadcast('showPageLoader', true);
      HttpService.get({
        url: `/RemoveCartLine/${idLine}`,
        cache: false
      })
        .then(async response => {
          if (response.status === 'OK') {
            updateParams(response.results);
            toastr.success(designation, message, {
              allowHtml: true,
              extraData: {
                template: 'import/js/libs/angular-toastr/toast_addtocart.tpl',
                data: {
                  img,
                  imgSrc
                }
              }
            });
            // Netwave
            const qty = product.quantity > 0 ? product.quantity : -(product.quantity);
            await NetwaveService.removeArticleFromCart(product, qty);
            deleteLineCallback(fromCart, response);
          } else {
            getTranslate()
              .then(messages => {
                toastr.warning(messages.errors.TryLater, messages.errors.ErrorHasOccurred, {
                  allowHtml: true,
                  extraData: {
                    template: 'import/js/libs/angular-toastr/toast_message.tpl'
                  }
                });
              })
              .catch(error => {
                console.error(error);
              });
          }
          $rootScope.$broadcast('showPageLoader', false);
        })
        .catch(error => {
          console.error(error);
        });
    }

    function deleteLineCallback(fromCart, response) {
      if (fromCart && !response.results.Cart.Products.length) {
        $rootScope.$broadcast('showPageLoader', true);
        $timeout(() => {
          $rootScope.$broadcast('showPageLoader', true);
        }, 450);
        window.location = $rootScope.backToStore;
      }
    }

    function itemQuantityInCart(id, hash = '') {
      return this.getParams()
        .then(data => {
          if (!data.HasCart) return 0;

          let qty = 0;
          data.Cart.Products.forEach(product => {
            if (product.IDProduct === id) {
              if (hash !== '') {
                if (product.Comment === hash) {
                  qty = product.Quantity;
                  return false;
                }
              } else {
                qty = product.Quantity;
                return false;
              }
            }
          });
          return qty;
        })
        .catch(error => {
          console.error(error);
        });
    }

    function checkAvailability() {
      return HttpService.post({
        url: '/Cart/checkAvailability'
      })
        .then(response => response)
        .catch(error => {
          console.error(error);
        });
    }

    function getProductPrice(priceObj, qty) {
      if (!priceObj.prices) {
        const prices = [
          {
            qty: 1,
            range: [0],
            HasDiscount: priceObj.HasDiscount,
            Discount: priceObj.Discount,
            HTDiscountedPrice: priceObj.HTDiscountedPrice,
            TTCDiscountedPrice: priceObj.TTCDiscountedPrice,
            HTPrice: priceObj.HTPrice,
            TTCPrice: priceObj.TTCPrice,
            DegressivePrice: priceObj.DegressivePrice
          }
        ];
        if (Object.entries(priceObj.DegressivePrice).length) {
          let i = 0;
          Object.entries(priceObj.DegressivePrice).forEach(([key]) => {
            const item = priceObj.DegressivePrice[key];
            prices[i].range.push(Number(key));

            prices.push({
              qty: Number(key),
              range: [Number(key)],
              HasDiscount: item.HasDiscount,
              Discount: item.Discount,
              HTDiscountedPrice: item.HTDiscountedPrice,
              TTCDiscountedPrice: item.TTCDiscountedPrice,
              HTPrice: item.HTPrice,
              TTCPrice: item.TTCPrice
            });

            i++;
          });
        }
        priceObj.prices = prices;
      }

      let current = {};
      priceObj.prices.forEach(price => {
        if (price.range && inRange(qty, price.range[0], price.range[1] || 10000)) {
          current = price;
          return false;
        }
      });
      return current;
    }

    function updateStore(visitorContext) {
      appPromise.$$state.value.Store = visitorContext.Store;
      appPromise.$$state.value.Visitor.Store = visitorContext.Visitor.Store;
    }

    function updateKey(key, object) {
      appPromise.$$state.value[key] = object;
    }

    async function emailExists({ email, openModal, action }) {
      const { IsLogged } = await getParams();
      if (!IsLogged) {
        try {
          const { status, errors } = await HttpService.post({
            url: '/TestMailExist',
            data: {
              Email: email
            }
          });
          if (status === 'ERROR') {
            const errorKey = Object.keys(errors).find(key => key === 'Global' && errors[key].Errors[0].ErrorMessage === 'AccountExist');
            if (errorKey !== undefined) {
              if (openModal) {
                ModalService.close();
                const options = {
                  mailRecognized: email
                };
                if (action) {
                  options.action = action;
                }
                ModalService.show('/Template/Authentication/ModalAuthentication', options, null, 'loginModalCtrl');
              }

              return {
                exists: true,
                errors: errors[errorKey].Errors
              };
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
      return {
        exists: false
      };
    }

    // Private
    function clearTemplateCache(refresh) {
      if (!refresh) return;

      const keys = [...$templateCache.getKeys()];
      keys.forEach(key => {
        if (key.indexOf('/') === 0 && !key.includes('ModalAddToCart')) {
          $templateCache.remove(key);
        }
      });
    }
  });
