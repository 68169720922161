export default angular
  .module('service.cp', [])

  /* @ngInject */
  .factory('PostCodeService', function () {
    const service = {
      getPostCodeInput
    };
    return service;

    function getPostCodeInput(ctrl, idHtml) {
      let controllerObject;
      if (ctrl.data !== undefined) {
        controllerObject = ctrl.data.PostCode;
      } else if (ctrl.formData !== undefined) {
        controllerObject = ctrl.formData.PostCode;
      } else if (ctrl.PostCode !== undefined) {
        controllerObject = ctrl.PostCode;
      }

      if (controllerObject) {
        if (controllerObject.startsWith('971') && controllerObject.length === 5) {
          if (controllerObject === '97150') {
            changeValueFromCodePoste('SAINT MARTIN', idHtml, ctrl);
          } else if (controllerObject === '97133') {
            changeValueFromCodePoste('SAINT BARTHELEMY', idHtml, ctrl);
          } else {
            changeValueFromCodePoste('GUADELOUPE', idHtml, ctrl);
          }
        } else if (controllerObject.startsWith('972')) {
          changeValueFromCodePoste('MARTINIQUE', idHtml, ctrl);
        } else if (controllerObject.startsWith('973')) {
          changeValueFromCodePoste('GUYANE FRANÇAISE', idHtml, ctrl);
        } else if (controllerObject.startsWith('974')) {
          changeValueFromCodePoste('RÉUNION', idHtml, ctrl);
        } else if (controllerObject.startsWith('975')) {
          changeValueFromCodePoste('SAINT-PIERRE-ET-MIQUELON', idHtml, ctrl);
        } else if (controllerObject.startsWith('976')) {
          changeValueFromCodePoste('MAYOTTE', idHtml, ctrl);
        } else if (controllerObject.startsWith('986')) {
          changeValueFromCodePoste('WALLIS ET FUTUNA', idHtml, ctrl);
        } else if (controllerObject.startsWith('987')) {
          changeValueFromCodePoste('POLYNÉSIE FRANÇAISE', idHtml, ctrl);
        } else if (controllerObject.startsWith('988')) {
          changeValueFromCodePoste('NOUVELLE-CALÉDONIE', idHtml, ctrl);
        }
      }
    }

    function changeValueFromCodePoste(value, idHtmlElement, ctrl) {
      const selectHtmlComponent = angular.element(idHtmlElement);
      let index;
      for (const item of selectHtmlComponent[0]) {
        if (item.label === value) {
          index = item;
        }
      }

      if (ctrl.data !== undefined) {
        ctrl.data.selectCountry.CountryCode = '';
        ctrl.data.selectCountry.Designation = index.label;
        ctrl.data.selectCountry.IDCountry = parseInt(index.value);
      } else if (ctrl.formData !== undefined) {
        ctrl.formData.IDCountry = index.value;
      } else if (ctrl.Country !== undefined) {
        ctrl.Country.CountryCode = '';
        ctrl.Country.Designation = index.label;
        ctrl.Country.IDCountry = parseInt(index.value);
      }
    }
  });
