export default angular
  .module('component.customization.extra', [])

  .component('customizationExtra', {
    require: {
      productCtrl: '^^productDetail'
    },
    bindings: {
      model: '<',
      tooltip: '@'
    },
    /* @ngInject */
    templateUrl: function ($sce) {
      return $sce.trustAsResourceUrl('/Template/Product/ProductCustomizationExtra');
    },
    /* @ngInject */
    controller: function ($scope, $element, $injector) {
      const ctrl = this;

      ctrl.$onInit = async () => {
        const response = await import(/* webpackChunkName: "customization.extra" */ 'Modules/Product/import/customization.extra.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('ImportCustomizationExtra');
        service.init(ctrl, $element, $scope);
      };
    }
  });
