
import 'import/js/plugins/jquery.lightgallery.js';
import 'Modules/Product/import/lightgallery.less';

export default angular
  .module('directive.carrousel.zoom', [])

  .directive('carrouselZoomIn', function () {
    return {
      restrict: 'A',
      controllerAs: 'ctrl',
      /* @ngInject */
      controller: function ($scope, $element) {
        const ctrl = this;
        ctrl.$onInit = async () => {
          console.log('inti');
          const owl = $('.carrousel-zoom-in');
          owl
            .find('a')
            .not('.video')
            .each(function () {
              const $this = $(this);
              $this.click(function () {
                const dynamicEl = [];
                owl
                  .find('a')
                  .not('.video')
                  .each(function () {
                    const $thisBis = $(this);
                    if ($thisBis.data('zoom-thumb-url')) {
                      dynamicEl.push({ thumb: $thisBis.data('zoom-thumb-url'), src: $thisBis.data('zoom-url') });
                    }
                  });
                owl
                  .on('onCloseAfter.lg', event => {
                    $(event.target).removeData('lightGallery');
                  })
                  .lightGallery({
                    nextHtml: '<i class="glyphicon glyphicon-chevron-right"></i>',
                    prevHtml: '<i class="glyphicon glyphicon-chevron-left"></i>',
                    fullscreenHtml: '<i class="fullscreen-icon glyphicon glyphicon-fullscreen"></i><i class="fullscreen-exit glyphicon glyphicon-fullscreen-exit"></i>',
                    closeHtml: '<i class="glyphicon glyphicon-remove"></i>',
                    dynamic: true,
                    dynamicEl,
                    enableSwipe: true,
                    index: owl.find('.owl-item.active').find('a').first().data('index'),
                    mode: 'lg-slide',
                    thumbnail: navigator.userAgent.toLowerCase().match(/(iphone|ipod)/) ? false : true
                  });

              });

            });
        };
      }
    };
  });
