import 'Modules/Category/import/facet.accordion.tpl';
import 'Modules/Category/import/facet.accordion.group.tpl';
import uiAccordion from 'angular-ui-bootstrap/src/accordion';

export default angular
  .module('directive.filters', [uiAccordion])

  .directive('filters', function () {
    return {
      restrict: 'E',
      scope: true,
      require: {
        categoryCtrl: '^^categoryProducts'
      },
      bindToController: {
        idCategory: '<',
        searchQuery: '@?',
        facetsBtns: '<',
        exclude: '<',
        webaspect: '@',
        scroll: '<',
        device: '<',
        categoryLevel: '<'
      },
      controllerAs: 'filtersCtrl',
      /* @ngInject */
      controller: function ($scope, $element, $injector) {
        if (window.isBot || window.isCache) return;
        const ctrl = this;

        $scope.localeSensitiveComparator = function (v1, v2) {
          return v1.value.localeCompare(v2.value);
        };

        ctrl.$onDestroy = () => {};

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "category" */ 'Modules/Category/import/category.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportCategory');
          service.initFilters(ctrl, $scope);
        };
      }
    };
  });
