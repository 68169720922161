const cartPostProcess = cart => {
  if (!cart) return cart;

  cart.Products.forEach(line => {
    if (line.Product.Themes.find(th => th.KeyTheme === 'CherryCheckout')) {
      line.isCherryCheckout = true;
    }
  });
  return cart;
};

export default cartPostProcess;
