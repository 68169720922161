import 'Modules/CapAdresse/components/component.capdropdown.tpl';
import 'Modules/CapAdresse/components/component.capdropdown.less';
import { CapDropdown } from '../helpers/capdropdown';

/**
 * @class angular.capadresse.capSearchCity
 * @desc Composant angularJS : affiche une liste de propositions de villes sous un champ de saisie
 * @memberOf angular.capadresse
 */
/* @ngInject */
class CapSearchCity extends CapDropdown {
  constructor($scope, $element, $transclude, $timeout, capAdresseService) {
    super($scope, $element, $transclude, $timeout);

    this.capService = capAdresseService;
  }

  $onInit() {
    if (!this.formCtrl) {
      this.disable();
      return;
    }

    this.formCtrl.capSearchCity = {
      setOption: this.setOption.bind(this),
      getOption: () => this.selection,
      clear: this.clear.bind(this)
    };

    this.initField();

    this.linkFields();
  }

  /**
   * When form is already filled, link with capAdress Values
   */
  initField() {
    if (!this.modelCtrl.$modelValue) {
      return;
    }

    this.selection = { locality: this.modelCtrl.$modelValue };
  }

  /**
   * Initialize relation with other cap fields
   * eg : empty locality when city changes
   */
  linkFields() {
    const linkedFields = ['capSearchLocality', 'capSearchStreet', 'capPlace', 'capBuilding'];

    this.modelCtrl.$viewChangeListeners.unshift(() => {
      const afterSelection = this.modelCtrl.$viewValue === this.parseOption(this.selection);

      if (afterSelection || this.cleared) {
        return;
      }

      const country = this.formCtrl.capCountry.$modelValue;

      if (this.capService.specificCountries.has(country)) {
        return;
      }

      linkedFields.forEach(field => {
        if (field in this.formCtrl) {
          this.formCtrl[field].clear();
        }
      });
    });
  }

  /**
   * Fetch options' list to display as dropdown
   * @param {string} userInput
   */
  async fetchList(userInput) {
    const country = this.formCtrl.capCountry.$modelValue;
    const countryId = angular.isObject(country) ? country.IDCountry : country;

    return this.capService.fetchLocalities(userInput, countryId);
  }

  /**
   * What to display from the option on the dropdown
   * @param {CapAddressOption} option
   */
  displayOption(option) {
    return option.inputOutput;
  }

  /**
   * Parse the value to save in the ngModel from the capAdress result
   * @param {CapAddressOption} option
   */
  parseOption(option) {
    if (option === undefined) {
      return;
    }

    return option.locality;
  }

  /**
   * Interface to other cap fields that can update this one's value
   * @param {CapAddressOption} option
   * @param {number} index optionnal: index of the option in options list
   */
  setOption(option, index) {
    super.select(option, index);

    if (option && 'additionalInfo_7' in option) {
      this.setPlace(option.additionalInfo_7); // capaddress place
    }
  }

  /**
   * Triggered on user's selection
   * @param {CapAddressOption} option
   * @param {number} index optionnal: index of the option in options list
   */
  select(option, index) {
    this.setOption(option, index);

    if ('capSearchLocality' in this.formCtrl) {
      this.formCtrl.capSearchLocality.setOption(option);
    }

    if ('capSearchStreet' in this.formCtrl) {
      this.formCtrl.capSearchStreet.focus();
    }
  }

  /**
   * For results including a place ("Lieu dit"), link the value to the correct field
   * @param {string} place
   */
  setPlace(place) {
    const placeFieldLoaded = 'capPlace' in this.formCtrl && this.formCtrl.capPlace.setValue;

    if (placeFieldLoaded) {
      this.formCtrl.capPlace.setValue(place);
    }

    if (!('capTrigger' in this.formCtrl)) {
      return;
    }

    this.formCtrl.capTrigger.open();

    if (placeFieldLoaded) {
      return;
    }

    this.formCtrl.capPlace = {
      value: place,
      clear: () => {}
    };
  }
}

export default angular
  .module('component.capSearchCity', [])
  .controller('capSearchCityCtrl', CapSearchCity)
  .component('capSearchCity', {
    templateUrl: 'Modules/CapAdresse/components/component.capdropdown.tpl',
    transclude: true,
    require: {
      formCtrl: '?^^capForm'
    },
    controller: 'capSearchCityCtrl'
  });
