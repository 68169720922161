export default angular
  .module('directive.be2bill-embedded', [])
  /* @ngInject */
  .directive('octBe2billEmbedded', function ($timeout, HttpService) {
    return {
      restrict: 'E',
      transclude: true,
      template: '<ng-transclude></ng-transclude>',
      scope: {
        fields: '=',
        endpoint: '@'
      },
      bindToController: true,
      controller: function () {},
      controllerAs: 'ctrl',
      link: scope => {
        const ctrl = scope.ctrl;

        if (!ctrl.endpoint) {
          throw new Error('Be2Bill endpoint must be configured');
        }

        $.ajax({
          // why ?
          url: ctrl.endpoint,
          dataType: 'script',
          cache: true,
          success: initB2Bill,
          error() {
            console.error('Unable to load Be2Bill scripts');
          }
        });

        async function initB2Bill() {
          const response = await HttpService.get({
            url: '/Be2Bill/API/Info',
            cache: true
          });

          ctrl.fields = be2bill.hostedFields({
            // Use your SDK API Key
            key: {
              id: response.APIKeyId,
              value: response.APIKeyValue
            },
            // Link and configure each hosted input field by providing the corresponding container ID
            fields: {
              card: {
                id: 'card-container',
                placeholder: 'XXXX XXXX XXXX XXXX',
                enableAutospacing: true,
                onInput: event => {
                  $timeout(() => {
                    scope.$apply(() => {
                      ctrl.hiddencard = event.type === 'invalid';
                    });
                  });
                }
              },
              expiry: {
                id: 'expiry-container',
                placeholder: 'MM/AA',
                onInput: event => {
                  $timeout(() => {
                    scope.$apply(() => {
                      ctrl.hiddenexpiry = event.type === 'invalid';
                    });
                  });
                }
              },
              cryptogram: {
                id: 'cryptogram-container',
                placeholder: '123',
                onInput: event => {
                  $timeout(() => {
                    scope.$apply(() => {
                      ctrl.hiddencryptogram = event.type === 'invalid';
                    });
                  });
                }
              }
            },
            // Choose the language for error messages
            location: 'fr'
          });
          $timeout(ctrl.fields.load(), 1000); // why ?
        }
      }
    };
  });
