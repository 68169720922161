export default angular
  .module('controller.newsletter', [])

  /* @ngInject */
  .controller('newsletterController', function ($element, $timeout, HttpService, AppService, LogService, ModalService, toastr) {
    const ctrl = this;

    ctrl.formData = {};

    ctrl.submit = () => {
      ctrl.loading = true;

      HttpService.post({
        url: '/Newsletter/Subscribe',
        data: ctrl.formData
      })
        .then(response => {
          AppService.getTranslate()
            .then(translate => {
              if (response.status === 'OK') {
                ctrl.submitted = true;
                toastr.success('', translate.messages.NewsletterOK, {
                  allowHtml: true,
                  extraData: {
                    template: 'import/js/libs/angular-toastr/toast_message.tpl'
                  }
                });
                $timeout(() => {
                  ctrl.form.$hideValidation();
                  $element.find('.form-control').blur();
                  ctrl.formData.Email = '';
                  ctrl.form.$resetValidation();
                }, 2000);
              } else if (response.status === 'ERROR_CONNECTION_EXPECTED') {
                LogService.redirect = {
                  url: null,
                  action: ctrl.submit
                };
                ModalService.show(
                  '/Template/Authentication/ModalAuthentication',
                  {
                    newsletterConnect: true,
                    mailRecognized: ctrl.formData.Email
                  },
                  null,
                  'loginModalCtrl'
                );
              } else {
                toastr.warning(translate.errors.TryLater, translate.errors.ErrorHasOccurred, {
                  allowHtml: true,
                  extraData: {
                    template: 'import/js/libs/angular-toastr/toast_message.tpl'
                  }
                });
              }
              ctrl.loading = false;
            })
            .catch(error => {
              console.error(error);
            });
        })
        .catch(error => {
          console.error(error);
        });
    };
  });
