export default angular
  .module('directive.countdown', [])

  /* @ngInject */
  .directive('countdown', function () {
    return {
      restrict: 'A',
      bindToController: {
        start: '@start',
        end: '@end'
      },
      /* @ngInject */
      controller: function ($interval) {
        const ctrl = this;
        ctrl.$onInit = async () => {
          $interval(counter, 1000);
        };

        function counter() {
          const today = new Date();
          const epochMicrotimeDiff = Math.abs(new Date(Date.UTC(0, 0, 1)).setFullYear(1));
          const startDate = parseInt(ctrl.start) / 10000 - epochMicrotimeDiff;
          const endDate = parseInt(ctrl.end) / 10000 - epochMicrotimeDiff;
          const startLag = new Date(startDate).getTimezoneOffset() * 60 * 1000;
          const endLag = new Date(endDate).getTimezoneOffset() * 60 * 1000;
          const start = new Date(startDate + startLag);
          const end = new Date(endDate + endLag);

          ctrl.status = today < end && today > start ? 'inProgress' : 'noActive';

          const dateDiff = end - today;
          const dayDate = dateDiff / (1000 * 3600 * 24);
          const day = Math.floor(dayDate);
          const hourDate = (dayDate - day) * 24;
          const hour = Math.floor(hourDate);
          const minDate = (hourDate - hour) * 60;
          const min = Math.floor(minDate);
          const secDate = (minDate - min) * 60;
          const sec = Math.floor(secDate);

          ctrl.day = day;
          ctrl.hour = hour;
          ctrl.min = min;
          ctrl.sec = sec;
        }
      },
      controllerAs: 'countdownCtrl'
    };
  });
