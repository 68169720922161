export default angular
  .module('directive.capCountry', [])
  /**
   * @class angular.capadresse.capCountry
   * @memberOf angular.capadresse
   * @desc Directive : A ajouter au champ d'adresse "Pays" et requise pour le fonctionnement de capadresse sur un formulaire
   */
  /* @ngInject */
  .directive('capCountry', () => ({
    restrict: 'A',
    require: ['ngModel', '?^^capForm'],
    controllerAs: 'ctrl',
    controller: function () {},
    link: (scope, _elt, _attrs, controllers) => {
      const [modelCtrl, formCtrl] = controllers;

      if (!formCtrl) {
        return;
      }

      const linkedFields = ['capSearchCity', 'capSearchLocality', 'capSearchStreet', 'capPlace', 'capBuilding'];

      formCtrl.capCountry = modelCtrl;

      modelCtrl.$viewChangeListeners.push(clearForm);

      /**
       * Empty every related field from the form when country value changes
       */
      function clearForm() {
        linkedFields.forEach(field => {
          if (!formCtrl[field]) {
            return;
          }

          formCtrl[field].clear();
        });
      }
    }
  }));
