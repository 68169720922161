export default angular.module('service.capadresse', []).factory('capAdresseService', capAdresseService);

/**
 * @class angular.capadresse.capAdresseService
 * @memberOf angular.capadresse
 */
/* @ngInject */
function capAdresseService($http) {
  /**
   * @constant
   * @type {Set<string>}
   * @memberOf angular.capadresse.capAdresseService#
   * @desc List of countries that have a specific behaviour on capadress
   */
  const specificCountries = new Set(['27', '215']); // eslint-disable-line no-undef

  return {
    specificCountries,
    fetchLocalities,
    fetchStreets,
    fetchStreetNumbers,
    validateForm
  };

  /**
   * @func
   * @desc Fetches a list of localities from CapAddress
   * @memberOf angular.capadresse.capAdresseService#
   * @param {string} userInput
   * @param {number} country country identifier
   * @param {boolean} inline wether localities chall be fetched from capAddress line api or search api
   */
  async function fetchLocalities(userInput, country, inline = false) {
    const path = inline ? 'LocalityLine' : 'SearchLocality';

    return $http({
      method: 'GET',
      url: `/CapAddress/${path}`,
      params: {
        input: userInput,
        countryId: country
      }
    })
      .then(({ data }) => data.localities)
      .catch(err => {
        if (err && err.status === 403) {
          return [];
        }

        return;
      });
  }

  /**
   * @func
   * @desc Fetches a list of streets from CapAddress
   * @memberOf angular.capadresse.capAdresseService#
   * @param {string} userInput
   * @param {Object} option Fields from CapAdress response addresses, representing currently selected locality
   * @param {boolean} inline wether localities chall be fetched from capAddress line api or search api
   */
  async function fetchStreets(userInput, { country, postalCode, locality, localityId }, inline = false) {
    const path = inline ? 'StreetLine' : 'SearchStreet';

    return $http({
      method: 'GET',
      url: `/CapAddress/${path}`,
      params: {
        input: userInput,
        countryId: country,
        locality,
        localityId,
        postalCode
      }
    })
      .then(({ data }) => data.streets)
      .catch(err => {
        if (err && err.status === 403) {
          return [];
        }

        return;
      });
  }

  /**
   * @func
   * @memberOf angular.capadresse.capAdresseService#
   * @desc Fetch the list of available numbers for currently selected street
   * @param {string} userInput
   * @param {Object} option Fields from capAddress responses, representing currently selected street
   */
  async function fetchStreetNumbers(userInput, { countryId, localityId, streetId }) {
    return $http({
      method: 'GET',
      url: '/CapAddress/SearchStreetNumbers',
      params: {
        input: userInput || '',
        countryId,
        localityId,
        streetId
      }
    })
      .then(({ data }) => data.numbers)
      .catch(err => {
        if (err && err.status === 403) {
          return [];
        }

        return;
      });
  }

  /**
   * @func
   * @memberOf angular.capadresse.capAdresseService#
   * @desc CapAdress validation api call to perform once when the form is submitted
   * @param {Object} address Representation of currently selected address
   */
  async function validateForm(address) {
    return $http({
      method: 'POST',
      url: '/CapAddress/ValidateForm',
      data: address,
      json: true
    });
  }
}
