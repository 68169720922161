import 'import/js/components/addtocart/component.addtocart.quantity.custom.js';
import 'import/js/components/addtocart/component.addtocart.quantity.js';
import 'import/js/components/addtocart/component.addtocart.js';

import 'import/js/components/input.quantity/component.input.quantity.js';

import 'import/js/components/addtowishlist/component.addtowishlist.js';
import 'import/js/components/addtoquotation/component.addtoquotation.advanced.js';
import 'import/js/directives/collapsemenu/directive.collapse.menu.js';
import 'import/js/directives/rzslider/directive.rzslider.js';
import 'import/js/directives/dropzone/directive.dropzone.js';

export default angular.module('components', [
  'component.addtocart.quantity.custom',
  'component.addtocart.quantity',
  'component.addtocart',
  'component.input.quantity',
  'component.addtowishlist',
  'component.addtoquotation.advanced',
  'directive.collapse.menu',
  'directive.rzslider',
  'directive.dropzone'
]);
