export default angular
  .module('directive.max.length', [])

  .directive('maxLengthWarning', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        let max = attrs.maxlength;
        let hasError;
        const message = attrs.message;
        scope.$watch(
          () => attrs.maxlength,
          value => {
            max = value;
          }
        );

        element.on('input', function () {
          const target = event.target;
          const value = target.value;
          const span = target.parentElement;

          if (value.length < max) {
            if (hasError) {
              span.parentElement.removeChild(span.parentElement.lastElementChild);
              hasError = false;
            }
            return;
          }
          span.insertAdjacentHTML('afterend', `<div class="max-length-warning">${message}${max}</div>`);
          hasError = true;
        });
      }
    };
  });
