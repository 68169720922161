function capFormCtrl() {}

export default angular
  .module('directive.capForm', [])
  /**
   * @class angular.capadresse.capForm
   * @memberOf angular.capadresse
   * @desc Directive : À ajouter sur l'élément `form` d'un formulaire pour activer le fonctionnement de capadresse sur celui-ci.
   */
  .directive('capForm', function (capAdresseService) {
    return {
      restrict: 'A',
      require: 'form',
      controller: capFormCtrl,
      controllerAs: 'formCtrl',
      link: (scope, elt, _attrs, formCtrl) => {
        let validated = false;

        elt.on('submit', async () => {
          if (validated) {
            return;
          }

          if (formCtrl.$invalid || formCtrl.$pristine) {
            return;
          }

          const { capSearchStreet, capSearchCity } = scope.formCtrl;

          const address = capSearchStreet.getOption() || capSearchCity.getOption();

          await capAdresseService.validateForm(address);

          validated = true;
        });
      }
    };
  });
