export default angular
  .module('directive.product.elementaries', [])

  .directive('productElementaries', function () {
    return {
      restrict: 'A',
      bindToController: {
        id: '<productElementaries',
        unavailable: '@'
      },
      controllerAs: 'elementariesCtrl',
      /* @ngInject */
      controller: function ($scope, $element, $injector) {
        const ctrl = this;

        ctrl.$onDestroy = () => {};

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "product.elementaries" */ 'Modules/Product/import/directive.product.elementaries.init.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportProductElementaries');
          service.init(ctrl, $scope, $element);
        };
      }
    };
  });
