export default angular
  .module('directive.header', [])

  .directive('mainHeader', function () {
    return {
      restrict: 'A',
      link: function () {
        setTimeout(() => {
          const $body = $(document.body);
          const $bandeauCovid = window.document.getElementById('bandeauCovid');

          if ($bandeauCovid) {
            $body.addClass('covid');
          } else {
            $body.removeClass('covid');
          }
        }, 10000);
      }
    };
  });
