export default angular
  .module('directive.delivery.tomorrow', [])

  .directive('deliveryTomorrow', function () {
    return {
      restrict: 'A',
      /* @ngInject */
      controller: function ($interval) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          $interval(counter, 1000);
        };

        function counter() {
          const today = new Date();
          const today15h = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 15, 0, 0, 0);
          const isWeekEnd = today.getDay() === 0 || today.getDay() === 6;

          if (today > today15h || isWeekEnd) {
            ctrl.countdownHtml = '';
          } else {
            let delta = Math.abs(today15h - today) / 1000;

            // calculate (and subtract) whole days
            const days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            const hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            const minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            const seconds = Math.floor(delta % 60);
            const dayName = 'demain';

            ctrl.countdownHtml = `${'Plus que' + '&nbsp;'}${hours}h` + `&nbsp;${minutes}min` + `&nbsp;${seconds}sec` + '&nbsp;' + 'pour être livré' + `&nbsp;${dayName}`;
          }
        }
      },
      controllerAs: 'deliveryTomorrowCtrl'
    };
  });
